export default [
    {
        emoji: "😺",
        name: "animals",
        slug: "animals"
    },
    {
        emoji: "⛽",
        name: "gas",
        slug: "gas"
    },
    {
        emoji: "💡",
        name: "lights",
        slug: "lights"
    }
]