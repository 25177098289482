import React from 'react';

import logo from './east-grange-house.png';

const HeaderLogo = () => (
    <div className="logo">
        <a href="/">
        <img alt="East Grange House" src={logo} />
        </a>
    </div>
    );

export default HeaderLogo;