import React from 'react';

import AnimalCard from './AnimalCard';

import './Animals.css';

import animals from './data/animal-data';

function showAdditional(additional) {
    const alertInformation = Object.entries(additional)
        .map(information => `${information[0]}: ${information[1]}`)
        .join('\n');
    alert(alertInformation);
};

const Animals = () => (
    <div className="animals"> 
        <h1>Animals</h1>
        {animals.map(animal => (
            <AnimalCard
            additional={animal.additional}
            diet={animal.diet}
            key={animal.name}
            name={animal.name}
            scientificName={animal.scientificName}
            showAdditional={showAdditional}
            size={animal.size}
          />
        ))}
    </div>
    );

export default Animals;