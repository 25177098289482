import React from 'react';

import logo from './east-grange-house.png';

const HeaderUser = () => (
    <div className="user">
        <a href="javascript:void(0);">Sign In</a>
    </div>
    );

export default HeaderUser;