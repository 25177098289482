import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import './App.css';

import Header from '../Header/Header';
import AppMenu from '../AppMenu/AppMenu';
import Instructions from '../Instructions/Instructions';
import Animals from '../Animals/Animals';


function App() {
    const greeting = "Hello";
    const displayAction = false;
    
    return ( 
        <div className="container">
            <Header />
            <AppMenu />
            
            <BrowserRouter>
                <Routes>
                    <Route path="/"  element={<Instructions />} />
                    <Route path="animals" element={<Animals />} />
                </Routes>
            </BrowserRouter>

        </div>

    );
}
  
export default App;
