import React from 'react';
import './Header.css';

import HeaderLogo from './HeaderLogo';
import HeaderTitle from './HeaderTitle';
import HeaderUser from './HeaderUser';

const Header = () => (
    <header className="header">
        <HeaderLogo />
        <HeaderTitle />
        <HeaderUser />
    </header>
    );

export default Header;