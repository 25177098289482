import React from 'react';

import emojis from './data/menu-data';

const displayEmojiName = event => alert(event.target.id); 

const AppMenu = () => (
    <ul>
        {
          emojis.map(emoji => (
            <li key={emoji.name}>
              <button>
                <span role="img" aria-label={emoji.name} id={emoji.name}>
                <a href={emoji.slug}>{emoji.emoji}</a>
                </span>
              </button>
            </li>
          ))
        }
    </ul>                
);

export default AppMenu;
