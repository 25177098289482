import React from 'react';
import './Animals.css'

export default function AnimalCard({ additional, diet, name, scientificName, showAdditional, size
}) {
  return (
    <div className="animal-wrapper">
      <h2>{name}</h2>
      <h3>{scientificName}</h3>
      <h4>{size}kg</h4>      
      <div><b>diet:</b> {diet.join(', ')}.</div>
      <button onClick={() => showAdditional(additional)}>More Info</button>
    </div>
  );
}
