export default [
  {
    name: 'Colin',
    scientificName: 'Colinerus leo',
    size: 4.5,
    diet: ['rabbit'],
    additional: {
      notes: 'Good at being a cat'
    }
  },
  {
    name: 'Middy',
    scientificName: 'Asthus leo',
    size: 3,
    diet: ['mice'],
    additional: {
      notes: 'Did well to last 15 years.'
    }
  },
  {
    name: 'Fluffy',
    scientificName: 'Wofferus leo',
    size: 5,
    diet: ['pigeons', 'fluff', 'weasels'],
    additional: {
      notes: 'Fluffy is not extinct.',
    }
  }
]